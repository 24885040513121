import React from 'react';
import { Typography, Tooltip, Form, Input, Button, Row, Col, Rate, Space, Radio, Checkbox, Result } from 'antd';
import { InfoCircleOutlined, SmileOutlined, StarOutlined } from '@ant-design/icons';

const { Title } = Typography;
const Global = props => {
    const [form1] = Form.useForm();

    //* Отправка формы
    async function user_send(v) {
        for( let value in v) {
            if(v[value] instanceof Array) {
               v[value] = v[value].join('!=@=!');
            }
        };
        v['hash'] = props.state.hash;
        v['method'] = 'user_send';
        await props.api(v,true);
    }


    return (
        <React.Fragment>
            {
                Object.keys(props.state.info).length > 0 ? (
                    <React.Fragment>
                        {
                            props.state.info.platform ? (
                                <Row style={{flexDirection:'column',justifyContent:'center'}} className="column900 wowload">
                                    <Col key={`kplatform`} style={{padding:20}} className="shadowscreen" >
                                        <Result
                                            icon={<SmileOutlined />}
                                            title="Пожалуйста, оставьте отзыв на площадке!"
                                        />
                                        <div className="fcenter">
                                            <Button href={props.state.info.data.url} target="_blank" shape="round" icon={<StarOutlined />} size="large">
                                                {props.state.info.data.name}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row style={{flexDirection:'column',justifyContent:'center'}} className="column900 wowload">
                                    <Col key={`kplatform`} style={{padding:20}} className="shadowscreen" >
                                        <Result
                                            icon={<SmileOutlined />}
                                            title="Спасибо за отзыв!"
                                        />
                                    </Col>
                                </Row>
                            )
                        }
                    </React.Fragment>
                ) : (
                    <Form name="form" form={form1} onFinish={user_send} className="form">
                        <Row style={{flexDirection:'column',justifyContent:'center'}} className="column900 wowload">
                            <Col key={`krate`} style={{padding:20}} className="shadowscreen" >
                                <Title style={{marginBottom:20,marginTop:10}} level={5}>На сколько Вам понравилось посещение клиники?</Title>
                                <div className="fcenter">
                                    <Form.Item className="max400" name={`rate`} rules={[{ required: true, message: 'Пожалуйста, укажите оценку!' }]}>
                                        <Rate allowClear={false} style={{ fontSize: 36 }} />
                                    </Form.Item>
                                </div>
                            </Col>
                            {
                                props.state.reviews.map( (rev, index) => 
                                    <Col key={`k${index}`} style={{padding:20}} className="shadowscreen" >
                                        <Title style={{marginBottom:20,marginTop:10}} level={5}>{rev.text}</Title>
                                        {rev.type === '1' && 
                                            <Form.Item className="max400" name={`ans-${rev.id}`}>
                                                <Input suffix={<Tooltip title="Ваш ответ"><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>} />
                                            </Form.Item>
                                        }
                                        {rev.type === '2' && 
                                            <div className="fcenter">
                                                <Form.Item className="max400" name={`ans-${rev.id}`} rules={[{ required: true, message: 'Пожалуйста, выберите один вариант ответа!' }]}>
                                                    <Radio.Group>
                                                        <Space direction="vertical">
                                                            {
                                                                rev.answers.map( (ans, index) => 
                                                                <Radio value={ans.id}>{ans.text}</Radio>
                                                                )
                                                            }
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        }
                                        {rev.type === '3' && 
                                            <div className="fcenter">
                                                <Form.Item className="max400" name={`ans-${rev.id}`} rules={[{ required: true, message: 'Пожалуйста, выберите один вариант ответа!' }]}>
                                                    <Checkbox.Group>
                                                        <Space direction="vertical">
                                                            {
                                                                rev.answers.map( (ans, index) => 
                                                                    <Checkbox value={ans.id}>{ans.text}</Checkbox>
                                                                )
                                                            }
                                                        </Space>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </div>
                                        }
                                        {rev.type === '4' && 
                                            <div className="fcenter">
                                                <Form.Item className="max400" name={`ans-${rev.id}`} rules={[{ required: true, message: 'Пожалуйста, укажите оценку!' }]}>
                                                    <Rate allowClear={false} style={{ fontSize: 36 }} />
                                                </Form.Item>
                                            </div>
                                        }
                                    </Col>
                                )
                            }
                            <Col key="k1_submit" style={{padding:20}}  >
                                <div className="fcenter">
                                    <Form.Item>
                                        <Button type="dashed" shape="round" htmlType="submit">Отправить</Button>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </React.Fragment>
    )
};

export default Global;